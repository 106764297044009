export default [
  {
    title: 'Materia',
    direction: 'vertical',
    id: '01',
    information: {
      year: 2022,
      support: 'Canvas',
      technique: 'Mixed media',
      type: 'Single',
      dimension: '60x80cm',
    },
    images: [
      { type: 'cover', src: '/artworks/00_000.jpeg' },
      { type: 'detail', src: '/artworks/00_001.jpeg' },
      { type: 'detail', src: '/artworks/00_002.jpeg' },
      { type: 'detail', src: '/artworks/00_003.jpeg' },
    ],
  },
  {
    title: 'Storm',
    direction: 'vertical',
    id: '02',
    information: {
      year: 2021,
      support: 'Canvas',
      technique: 'Mixed media',
      // price: '200 Fr.-',
      type: 'Single',
      dimension: '80x100cm',
    },
    images: [
      { type: 'cover', src: '/artworks/01_000.jpeg' },
      { type: 'detail', src: '/artworks/01_001.jpeg' },
      { type: 'detail', src: '/artworks/01_002.jpeg' },
      { type: 'detail', src: '/artworks/01_003.jpeg' },
    ],

  },
  {
    title: 'Memories',
    direction: 'small',
    id: '03',
    information: {
      year: 2022,
      support: 'Canvas',
      technique: 'Mixed media',
      // price: '400',
      type: 'Single',
      dimension: '20x60cm',
    },
    images: [
      { type: 'cover', src: '/artworks/02_000.jpeg' },
      { type: 'detail', src: '/artworks/02_001.jpeg' },
      { type: 'detail', src: '/artworks/02_002.jpeg' },
      { type: 'detail', src: '/artworks/02_003.jpeg' },
    ],

  },
  {
    title: 'Rust',
    direction: 'vertical',
    id: '04',
    information: {
      year: 2022,
      support: 'Canvas',
      technique: 'Mixed media',
      // price: '120 Fr.-',
      type: 'Single',
      dimension: '50x60cm',
    },
    images: [
      { type: 'cover', src: '/artworks/03_000.jpeg' },
      { type: 'detail', src: '/artworks/03_001.jpeg' },
      { type: 'detail', src: '/artworks/03_002.jpeg' },
      { type: 'detail', src: '/artworks/03_003.jpeg' },
    ],

  },
  {
    title: 'Together',
    direction: 'small',
    id: '05',
    information: {
      year: 2022,
      support: 'Canvas',
      technique: 'Mixed media',
      // price: 'Sold',
      type: 'Single',
      dimension: '40x120cm',
    },
    images: [
      { type: 'cover', src: '/artworks/04_000.jpeg' },
      { type: 'detail', src: '/artworks/04_001.jpeg' },
      { type: 'detail', src: '/artworks/04_002.jpeg' },
      { type: 'detail', src: '/artworks/04_003.jpeg' },
    ],

  },
  {
    title: 'Smart',
    direction: 'square',
    id: '06',
    information: {
      year: 2021,
      support: 'Canvas',
      technique: 'Mixed media',
      // price: '200',
      type: 'Single',
      dimension: '80x80cm',
    },
    images: [
      { type: 'cover', src: '/artworks/05_000.jpeg' },
      { type: 'detail', src: '/artworks/05_001.jpeg' },
      { type: 'detail', src: '/artworks/05_002.jpeg' },
      { type: 'detail', src: '/artworks/05_003.jpeg' },
    ],
  },
  {
    title: 'Follow your heart',
    direction: 'square',
    id: '07',
    information: {
      year: 2021,
      support: 'Canvas',
      technique: 'Mixed media',
      // price: '150 Fr.-',
      type: 'Series',
      dimension: '80x80cm',
    },
    images: [
      { type: 'cover', src: '/artworks/06_000.jpeg' },
      { type: 'detail', src: '/artworks/06_001.jpeg' },
      { type: 'detail', src: '/artworks/06_002.jpeg' },
      { type: 'detail', src: '/artworks/06_003.jpeg' },
    ],
  },
  {
    title: 'Calls',
    direction: 'small',
    id: '08',
    information: {
      year: 2022,
      support: 'Canvas',
      technique: 'Mixed media',
      // price: '450 Fr.-',
      type: 'Single',
      dimension: '20x60cm',
    },
    images: [
      { type: 'cover', src: '/artworks/07_000.jpeg' },
      { type: 'detail', src: '/artworks/07_001.jpeg' },
      { type: 'detail', src: '/artworks/07_002.jpeg' },
      { type: 'detail', src: '/artworks/07_003.jpeg' },
    ],
  },
  {
    title: 'Lo strappo',
    direction: 'square',
    id: '09',
    information: {
      year: 2022,
      support: 'Canvas',
      technique: 'Mixed media',
      // price: '150 Fr.-',
      type: 'Series',
      dimension: '30x30cm',
    },
    images: [
      { type: 'cover', src: '/artworks/08_000.jpeg' },
      { type: 'detail', src: '/artworks/08_001.jpeg' },
      { type: 'detail', src: '/artworks/08_002.jpeg' },
      { type: 'detail', src: '/artworks/08_003.jpeg' },
    ],
  },
  {
    title: 'Lo strappo',
    direction: 'square',
    id: '10',
    information: {
      year: 2022,
      support: 'Canvas',
      technique: 'Mixed media',
      // price: '150 Fr.-',
      type: 'Series',
      dimension: '30x30cm',
    },
    images: [
      { type: 'cover', src: '/artworks/09_000.jpeg' },
      { type: 'detail', src: '/artworks/09_001.jpeg' },
      { type: 'detail', src: '/artworks/09_002.jpeg' },
      { type: 'detail', src: '/artworks/09_003.jpeg' },
    ],
  },
  {
    title: 'Lo strappo',
    direction: 'square',
    id: '11',
    information: {
      year: 2022,
      support: 'Canvas',
      technique: 'Mixed media',
      // price: '150 Fr.-',
      type: 'Series',
      dimension: '30x30cm',
    },
    images: [
      { type: 'cover', src: '/artworks/10_000.jpeg' },
      { type: 'detail', src: '/artworks/10_001.jpeg' },
      { type: 'detail', src: '/artworks/10_002.jpeg' },
      { type: 'detail', src: '/artworks/10_003.jpeg' },
    ],
  },
  {
    title: 'Cracks',
    direction: 'square',
    id: '12',
    information: {
      year: 2022,
      support: 'Canvas',
      technique: 'Mixed media',
      // price: '150 Fr.-',
      type: 'Series',
      dimension: '30x30cm',
    },
    images: [
      { type: 'cover', src: '/artworks/11_000.jpeg' },
      { type: 'detail', src: '/artworks/11_001.jpeg' },
      { type: 'detail', src: '/artworks/11_002.jpeg' },
      { type: 'detail', src: '/artworks/11_003.jpeg' },
    ],
  },
  {
    title: 'Cracks',
    direction: 'square',
    id: '13',
    information: {
      year: 2022,
      support: 'Canvas',
      technique: 'Mixed media',
      // price: '150 Fr.-',
      type: 'Series',
      dimension: '30x30cm',
    },
    images: [
      { type: 'cover', src: '/artworks/12_000.jpeg' },
      { type: 'detail', src: '/artworks/12_001.jpeg' },
      { type: 'detail', src: '/artworks/12_002.jpeg' },
      { type: 'detail', src: '/artworks/12_003.jpeg' },
    ],
  },
  {
    title: 'Cracks',
    direction: 'square',
    id: '14',
    information: {
      year: 2022,
      support: 'Canvas',
      technique: 'Mixed media',
      // price: '150 Fr.-',
      type: 'Series',
      dimension: '30x30cm',
    },
    images: [
      { type: 'cover', src: '/artworks/13_000.jpeg' },
      { type: 'detail', src: '/artworks/13_001.jpeg' },
      { type: 'detail', src: '/artworks/13_002.jpeg' },
      { type: 'detail', src: '/artworks/13_003.jpeg' },
    ],
  },
]
