<template>
  <div :class="`w-full ${type}`">
    <h1
      :class="`flex mb-0.5 md:mb-0 text-center justify-center pb-default-half ${styleComputed.title} tracking-[-1px] space-x-0.5 font-medium`"
    >
      <span>{{ artwork.id }}</span>
      <span class="font-thin">/</span>
      <span>{{ artwork.title }}</span>
    </h1>
    <ul class="w-full list-none flex flex-col">
      <li v-for="(value, name, index) in artworkInformation" :key="index" :class="`${styleComputed.list}`">
        <p class="capitalize">{{ name }}</p>
        <p>{{ value }}</p>
      </li>
      <li v-if="showRequest" :class="`${styleComputed.list}`">
        <p>Request information</p>
        <a :href="`mailto:kydotu@bluewin.ch?subject=Request for information - ${artwork.title}`" target="_blank" rel="noopener noreferrer">
          Contact me via Email
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup>
const props = defineProps({
  artwork: { type: Object, default: () => ({}) },
  type: { type: String, default: 'index' },
  showRequest: { type: Boolean, default: false }
});

const order = ['year', 'dimension', 'technique', 'support', 'type'];

const artworkInformation = computed(() => {
  const ordered = {};
  order.forEach((key) => (ordered[key] = props.artwork.information[key]));
  return ordered;
});

const styleComputed = computed(() => {
  return {
    title: {
      index: 'text-4xl xl:text-4xl text-black md:text-neutral-400 md:hover:text-black',
      page: 'text-4xl xl:text-5xl text-black'
    }[props.type],
    list: {
      index: 'text-sm',
      page: 'text-sm 2xl:text-base'
    }[props.type]
  };
});
</script>

<style lang="scss" scoped>
div.index {
  &:hover {
    & > h1 {
      @apply text-black;
    }
  }
}

div {
  ul {
    li {
      @apply flex flex-row;
      @apply border-t border-neutral-300;
      p,
      a {
        @apply w-1/2;
        @apply py-1 xl:py-[2.5px];
        &:first-child {
          @apply text-neutral-400;
        }
      }
    }
  }
}
</style>
