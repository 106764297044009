export default {
  about: {
    en: {
      bio: `I am Daniela Türkyilmaz, a self-taught Swiss artist based in Bellinzona and having my own studio is the realisation of a life-long dream. It is here that I express my emotions in a variety of ways; using the colours of nature on canvas or other media. I learn from different sources, exploring and experimenting with new techniques to keep my creativity stimulated. I am particularly passionate about the abstract style as it allows a free execution and subsequent interpretation of the painting. Those who contemplate it can identify aspects that are different for everyone and make them their own, until they connect with the work itself and imagine it into an environment.`,
      more: `My process is spontaneous and full of curiosity. I create with the help of different elements like fabric collages, papers, sand, lettering, stencils and materials such as marble dust, chalk, structuring pastes, inks, coffee, acrylics and pigments. Materials that create layers and give depth to the paintings. I love to mix these techniques even in a single work, which always adds excitement and life to it's development. The signs, imprinted with multiple tools, are also an integral part of my expression and often present. My most recent exploration has been to a technique that consisting of mixing cold wax with oil paints.  This allows me to add and remove material from wooden supports with a special technique, creating a myriad of colour and texture effects.`,
    },
    it: {
      bio: `Sono Daniela Türkyilmaz, un'artista svizzera con sede a Bellinzona. Il mio atelier nasce da un progetto personale da tempo desiderato. È un luogo dove esprimere le mie emozioni suscitate per esempio dai colori della natura e trasferirle su tela o altri supporti. Sono essenzialmente un'autodidatta, traggo comunque insegnamento da più fonti per sperimentare nuove tecniche e donare stimolo alla mia creatività. Prediligo lo stile astratto, trovo permetta una libera esecuzione e successiva interpretazione del dipinto. Chi lo contempla può individuare aspetti per ognuno differenti e farli propri, fino ad entrare in connessione con l'opera stessa ed immaginarla all'interno di un ambiente`,
      more: `Il mio processo è spontaneo e pieno di curiosità. Creo con l'ausilio di collage di tessuti, carte, sabbie, scritte, stencil e materiali quali la polvere di marmo, il gesso, paste strutturanti, inchiostri, caffè, acrilici e pigmenti. Materiali che creano stratificazioni e donano profondità alle opere. Amo mischiare queste tecniche anche in un unico lavoro, che è sempre diverso ed imprevedibile nel suo divenire. I segni, impressi tramite molteplici utensili, sono anch'essi parte integrante della mia espressione e spesso presenti.  Ultimamente mi sto dedicando ad una tecnica che consiste nel mischiare una cera fredda a dei colori ad olio, permette di aggiungere e togliere con speciali accorgimenti materiale da supporti in legno creando interessanti effetti di colore e texture.`
    }
  },
  contacts: {
    email: 'kydotu@bluewin.ch',
    socials: [
      { name: 'Instagram', url: 'https://www.instagram.com/materica_mente/' },
      { name: 'Mail', url: 'mailto:kydotu@bluewin.ch' }
    ]
  }
}
