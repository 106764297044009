import { defineStore } from 'pinia';
import artworks from '~/public/artworks.js';
import information from '~/public/information';
import { Artwork, IndexState } from '~/interfaces';

export const useStore = defineStore({
  id: 'index',
  state: (): IndexState => ({
    artworks,
    information,
    view: 'artworks',
    indexIsOpen: false,
    selected: null,
    showLanding: true
  }),
  actions: {
    setView(view: 'artworks' | 'index' | 'about'): void {
      if ((view === 'index' && this.view === 'index') || (view === 'about' && this.view === 'about')) {
        this.view = 'artworks';
      } else {
        this.view = view;
      }
    },
    setSelected(artwork: Artwork): void {
      this.selected = artwork;
      if (this.indexIsOpen) {
        this.view = 'artworks';
      }
    },
    setIndexStatus(payload: boolean): void {
      this.indexIsOpen = payload;
    },
    setLandingStatus(payload: boolean): void {
      this.showLanding = payload;
    }
  },
  getters: {
    getArtworkById: (state) => (id: number) => state.artworks.find((a) => a.id === id),
    canNavigate: (state): boolean => state.view === 'artworks' && !!state.selected && !state.indexIsOpen
  }
});
